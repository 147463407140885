<template>
  <div>
    <ItemsNav />
    <pageNotFound v-if="notFound" />
    <div v-else>
      <div class="m-t-4 m-md-t-0">
        <header
          v-if="item !== null"
          class="main__title"
        >
          <div class="container">
            <div class="row">
              <div class="col main__title__inner">
                <span
                  class="main__title__action--left"
                  @click="goBack"
                >
                  <i class="uil uil-arrow-left" />
                </span>
                <h1>{{ item ? item.attributes.description : '' }}</h1>
              </div>
              <div
                v-if="viewableOnWebsite"
                class="viewableOnWebsite"
              >
                <a
                  :href="viewableOnWebsite"
                  target="_blank"
                >view on website <i class="uil uil-external-link-alt" /></a>
              </div>
            </div>
          </div>
        </header>
      </div>

      <loader v-if="isPending" />

      <div
        v-if="!isPending && item.$$price.mediafeedid"
        class="banner__promo pointer"
        @click="showPromoInfo = true"
      >
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="banner__content">
                {{ item.$$price.$$mediafeed.title }}<!--<div><small> ({{ $t('items.promoBanner') }})</small></div>-->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isPending && item.attributes.category === 'outlet'"
        class="banner__outlet"
      >
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="banner__content">
                {{ $t('items.cat.outlet') }} - {{ item.attributes.condition }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isPending"
        class="product__detail"
      >
        <div class="product__detail__image">
          <loader v-if="isPending" />
          <div
            v-else
            class="container"
          >
            <div class="row">
              <div class="col product__detail__brand">
                <div>
                  <brand-logo
                    v-if="item.attributes.item_brand"
                    :brand="item.attributes.item_brand"
                  />
                </div>
              </div>
              <div
                v-if="item.attributes.preorder && ['yes', 'ja', 'oui', 'sì', 'si'].includes(item.attributes.preorder.toLowerCase())"
                class="col product__detail__brand"
                style="text-align: right;"
              >
                <div
                  class="badge badge-promo"
                >
                  Pre-order
                </div>
              </div>
            </div>
            <loading-overlay :loading="variantPending">
              <div class="row align-items-center">
                <div class="col-lg-8 text-center m-md-b-4">
                  <picture
                    v-if="item.attributes.images
                      && item.attributes.images.image
                      && item.attributes.images.image.highres"
                  >
                    <!--This is the optional AVIF image-->
                    <source
                      type="image/avif"
                      :srcset="item.attributes.images.image.highres_avif"
                    >
                    <!--This is the fallback JPEG image-->
                    <img
                      :src="item.attributes.images.image.highres"
                      lazy="true"
                      class="product__detail__productimage"
                      @click="openGallery()"
                    >
                  </picture>

                  <img
                    v-else
                    class="product__detail__productimage"
                    src="@/assets/img/product_placeholder.jpg"
                  >
                </div>
                <div class="col-lg-4 product__detail__options">
                  <variants
                    :relations="relations"
                    :item="item"
                  />
                  <div class="product__detail__price m-b-2">
                    <div>
                      <label>{{ $t('items.msrp') }}</label>
                      <span v-if="item.$$price.oldmsrp">
                        <span class="oldprice">
                          <price
                            :price="item.$$price.oldmsrp"
                            :currency="item.$$price.currency"
                          />
                        </span>
                        <span class="newprice">
                          <price
                            :price="item.$$price.msrp"
                            :currency="item.$$price.currency"
                          />
                        </span>
                      </span>
                      <span v-else>
                        <span>
                          <price
                            :price="item.$$price.msrp"
                            :currency="item.$$price.currency"
                          />
                        </span>
                      </span>
                    </div>
                    <div v-if="!showRetailPrices">
                      <label>{{ $t('items.yourPrice') }}</label>
                      <span v-if="item.$$price.oldprice">
                        <span class="oldprice">
                          <price
                            :price="item.$$price.oldprice"
                            :currency="item.$$price.currency"
                          />
                        </span>
                        <span class="newprice">
                          <price
                            :price="item.$$price.price"
                            :currency="item.$$price.currency"
                          />
                        </span>
                      </span>
                      <span v-else>
                        <span>
                          <price
                            :price="item.$$price.price"
                            :currency="item.$$price.currency"
                          />
                        </span>
                      </span>
                    </div>
                  </div>

                  <div class="product__detail__availability m-b-4">
                    <span>
                      <label>
                        {{ $t('items.stock') }}
                        <a
                          href="#"
                          @click.prevent="scrollTo('availability')"
                        >Details</a>
                      </label>
                      <availability :availability="item.$$availability" />
                    </span>

                    <span
                      v-if="showAssignable"
                      class="product__detail__assignable"
                    >
                      <!--<loading-overlay :loading="!assignable.loaded" />-->

                      <loader
                        v-if="!assignable.loaded"
                        size="tiny"
                      />
                      <template v-else>
                        <a
                          href="#"
                          @click.prevent="scrollTo('assignable-exact')"
                        >
                          {{ assignable.counts.exactMatch }}
                          <span class="explainer">{{ $t('items.assignableAvailable') }}</span></a>
                        <a
                          href="#"
                          @click.prevent="scrollTo('assignable-similar')"
                        >
                          {{ assignable.counts.other }}
                          <span class="explainer"> {{ $t('items.similarAssignableAvailable') }}</span>
                        </a>
                      </template>
                    </span>
                  </div>

                  <button
                    v-if="item.customizer && ENABLE_BUILD_YOUR_OWN"
                    type="submit"
                    class="button button--primary w-100 m-b-1"
                    @click="goToBuildYourOwn"
                  >
                    {{ $t('customizer.buildyourown') }}
                  </button>
                  <button
                    type="submit"
                    class="button button--danger w-100"
                    :disabled="!item.$$availability
                      || item.$$availability.filter(o => o.date).length === 0"
                    @click="addToCart"
                  >
                    {{ $t('cart.addToCart') }}
                  </button>
                </div>
              </div>
            </loading-overlay>
          </div>
        </div>
      </div>

      <div
        v-if="!isPending && isConfidential"
        class="banner__confidential"
      >
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="banner__content">
                {{ $t('confidential_until') }} {{ formatDate(item.attributes.confidential_until) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isPending"
        class="product__detail__subnav__holder"
      >
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="product__detail__subnav">
                <a
                  href="#"
                  @click.prevent="scrollTo('specs')"
                >{{ $t('items.specifications') }}</a>
                <a
                  v-if="hasGeo"
                  href="#"
                  @click.prevent="scrollTo('geo')"
                >{{ $t('items.dimensions') }}</a>
                <a
                  v-if="hasVideoGallery"
                  href="#"
                  @click.prevent="scrollTo('videos')"
                >{{ $t('items.videoGallery') }}</a>
                <a
                  v-if="hasMediaGallery"
                  href="#"
                  @click.prevent="scrollTo('photos')"
                >{{ $t('items.mediaGallery') }}</a>
                <a
                  v-if="item.attributes.platform_description"
                  href="#"
                  @click.prevent="scrollTo('platform_description')"
                >{{ attributeTranslations['platform_description']
                  ? attributeTranslations['platform_description']
                  : key }}</a>
                <a
                  v-if="item.attributes.technology"
                  href="#"
                  @click.prevent="scrollTo('technology')"
                >{{ attributeTranslations['technology']
                  ? attributeTranslations['technology']
                  : key }}</a>
                <a
                  href="#"
                  @click.prevent="scrollTo('availability')"
                >{{ $t('items.stock') }}</a>
                <a
                  v-if="downloads.length > 0"
                  href="#"
                  @click.prevent="scrollTo('downloads')"
                >{{ $t('items.download') }}</a>
                <a
                  v-if="relations.outlet"
                  href="#"
                  @click.prevent="scrollTo('outlet')"
                >{{ $t('items.cat.outlet') }}</a>
                <a
                  v-if="relations.serviceparts"
                  href="#"
                  @click.prevent="scrollTo('serviceparts')"
                >{{ $t('items.serviceparts') }}</a>
                <!--a
                  v-if="item.attributes.url_frame_magazine"
                  href="#"
                  @click.prevent="scrollTo('magazine')"
                >{{ $t('items.discoverBikeTitle', {type:item.attributes.model}) }}</a-->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isPending"
        class="container"
      >
        <div class="row">
          <div class="col-lg-6 m-t-4">
            <div
              v-if="item.attributes.outlet_description"
              id="outlet_description"
              class="product__platform-description m-b-4"
            >
              <SectionTitle>
                {{ attributeTranslations['outlet_description']
                  ? attributeTranslations['outlet_description']
                  : 'outlet_description' }}
              </SectionTitle>
              <!-- eslint-disable -->
            <div v-html="item.attributes.outlet_description" />
          </div>

          <specifications
            id="specs"
            :item="item"
          />
          <div
            v-if="item.attributes.technology"
            id="technology"
            class="m-t-4"
          >
            <SectionTitle>
              {{ attributeTranslations['technology'] ? attributeTranslations['technology']: key }}
            </SectionTitle>

            <div class="product__technology m-b-2">
              <div
                v-for="technology in item.attributes.technology"
                :key="technology.description"
                class="product__technology__item"
              >
                <div
                  class="product__technology__image pointer"
                  @click.prevent="openTechnologyModal(technology)"
                >
                  <img :src="technology.images && technology.images.image1 ? technology.images.image1.thumb : ''">
                </div>
                <div class="product__technology__content">
                  <p v-html="technology.description" />
                  <a
                    href="#"
                    class="more-link"
                    @click.prevent="openTechnologyModal(technology)"
                  >{{ $t('items.readMore') }}</a>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="col-lg-6">
          <div
            v-if="hasMediaGallery"
            id="photos"
            class="m-t-4"
          >
            <SectionTitle>
              {{ $t('items.mediaGallery') }}
            </SectionTitle>
            <media-gallery
              ref="mediaGallery"
              :key="item.id"
              :images="mediaGallery"
              :first="item.attributes.images.image"
            />
          </div>
          <div v-else>
            <!--galery should always be on the page (not visible), used in lightbox main image-->
            <media-gallery
              ref="mediaGallery"
              :key="item.id"
              :images="mediaGallery"
              :first="item.attributes.images.image"
            />
          </div>
          <ListItem
            v-if="hasMedia"

            icon-before
            arrow
          >
            <i class="uil uil-scenery" />
            <a
              class="pointer"
              @click="mediaAside = true"
            >{{ $t('items.moreMedia') }}</a>
          </ListItem>

          <div
              v-if="hasVideoGallery"
              id="videos"
              class="m-t-3"
            >
            <SectionTitle>
              {{ $t('items.videoGallery') }}
            </SectionTitle>
            <div class="video__gallery">
              <div class="video__gallery__preview">
                <youtube :video-id="videoGallery[`video${currentVideo}`]"></youtube>
              </div>
              <div class="video__gallery__overview">
                <span
                  v-for="(videoId, key) in videoGallery"
                  :key="videoId"
                  @click="gotoVideo(key)"
                  :class="{ 'video--active' : (key === `video${currentVideo}`) }"
                  :style="{ backgroundImage: 'url(https://img.youtube.com/vi/' + videoId + '/hqdefault.jpg)' }">
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="item.attributes.platform_description"
            id="platform_description"
            class="product__platform-description m-t-4"
          >
            <SectionTitle>
              {{ attributeTranslations['platform_description']
                ? attributeTranslations['platform_description']
                : key }}
            </SectionTitle>
            <!-- eslint-disable -->
            <div v-html="item.attributes.platform_description" />
          </div>

          <div
            v-if="item.attributes.detail_description"
            id="detail_description"
            class="product__detail-description m-t-4"
          >
            <SectionTitle>
              {{ attributeTranslations['detail_description']
                ? attributeTranslations['detail_description']
                : key }}
            </SectionTitle>
            <div v-html="item.attributes.detail_description" />
          </div>

          <div
            v-if="downloads.length > 0 || hasMedia"
            id="downloads"
            class="m-t-4"
          >
            <SectionTitle>{{ $t('items.download') }}</SectionTitle>

            <ListItem
              v-for="download in downloads"
              :key="download.label"
              icon-before
            >
              <i class="uil uil-file-download-alt" />
              <a
                :href="download.url"
                target="_blank"
              >{{ download.label }}</a>
            </ListItem>
          </div>

          <div
            id="availability"
            class="m-t-4"
          >
            <SectionTitle>{{ $t('items.stock') }}</SectionTitle>
            <table
              v-if="availabilityTable.length >= 1"
              class="table table--striped"
            >
              <tr
                v-for="quantity in availabilityTable"
                :key="quantity.fromQ + quantity.text"
              >
                <td width="20%">
                  <strong>{{ quantity.quantity }}</strong>
                </td>
                <td
                  v-if="quantity.type === 'text'"
                  width="40%"
                >
                  {{ quantity.text }}
                </td>
                <td
                  v-if="quantity.type === 'text'"
                  width="40%"
                >
                  <availability
                    size="small"
                    :availability="item.$$availability"
                    :quantity="quantity.fromQ"
                  />
                </td>
                <td
                  v-if="quantity.type === 'contactUs'"
                  width="80%"
                  colspan="2"
                >
                  {{ $t('items.contactUsForMore') }}
                </td>
              </tr>
            </table>
            <Message
              v-else
              type="warning"
            >
              {{ $t('items.currentlyNotAvailable') }}
            </Message>
          </div>
          <template  v-if="showAssignable">
            <template v-if="assignable.loaded"
  >
              <div
                  id="assignable-exact"
                  class="m-t-4 product__assignable"
              >
                <SectionTitle>{{ $t('items.assignable') }}</SectionTitle>
                <ordered-item
                    class="product__detail__assignable-item"
                    v-for="item in assignable.exactMatch"
                    :key="item.items[0].id"
                    :item="item"
                    :interactive="false"
                    :id-selectable="true"
                    @click.native="assignOrderedItem(item, $event)"
                />
                <div class="subtle" v-if="assignable.exactMatch.length===0">
                  {{ $t('items.noItems') }}
                </div>
              </div>
              <div
                  id="assignable-similar"
                  class="m-t-4 product__assignable"
              >
                <SectionTitle>{{ $t('items.similarAssignable') }}</SectionTitle>
                <ordered-item
                    class="product__detail__assignable-item"
                    v-for="item in assignable.other"
                    :key="item.items[0].id"
                    :item="item"
                    :interactive="false"
                    :id-selectable="true"
                    @click.native="assignOrderedItem(item, $event)"
                />
                <div class="subtle" v-if="assignable.other.length===0">
                  {{ $t('items.noItems') }}
                </div>
              </div>
            </template>
            <div v-else class="m-t-4">
              <loader/>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="m-t-4">
      <geo
        v-if="hasGeo"
        id="geo"
        :item="item"
      />
    </div>

    <div class="banner__magazine m-t-8" v-if="!isPending && item.attributes.url_frame_magazine" id="magazine">
      <div class="banner__magazine__overlay" :style="{ backgroundImage : 'url('+item.attributes.images.image_frame_magazine.highres+')'}"></div>
      <div class="container">
        <div class="row">
          <div class="col banner__magazine__inner">
            <div class="banner__magazine__image">
              <img :src="item.attributes.images.image_frame_magazine.thumb" v-if="item.attributes.images.image_frame_magazine">
              <img src="../assets/img/magazine-example.jpg" v-else>
            </div>
            <div class="banner__magazine__content">
              <span class="banner__title">{{ $t('items.discoverBikeTitle', {type:item.attributes.model}) }}</span>
              <span class="banner__content">{{ $t('items.discoverBikeText') }}</span>
              <span class="banner__cta">
                <a :href="item.attributes.url_frame_magazine + '?email=' + userEmail" target="_blank" class="button button--danger">
                  <i class="uil uil-book-open" />
                  {{ $t('items.discoverBikeButton') }}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row" v-if="relations.outlet" id="outlet">
        <div class="col m-t-4">
          <SectionTitle>{{ $t('items.cat.outlet') }}</SectionTitle>
        </div>
      </div>
      <div class="row" v-if="relations.outlet">
        <div class="col-md-3 col-sm-6" v-for="(outletItem, index) in relations.outlet.filter((o,i) => i < (showMoreOutlet ? relations.outlet.length : 4))" :key="outletItem.itemId">
          <itemcardrelated
            :id="outletItem.id"
            :summaryitem="outletItem"
            gtag-list="outlet"
            :gtag-position="index"
            @click="goToItem(outletItem)"
          />
        </div>
      </div>
      <div class="row" v-if="relations.outlet && relations.outlet.length > 4">
          <div
          class="col"
          @click="showMoreOutlet = !showMoreOutlet"
        >
          <span
            v-if="showMoreOutlet"
            class="link-expand-filters less"
          >{{ $t('items.showLess') }}</span>
          <span
            v-else
            class="link-expand-filters"
          >{{ $t('items.showMore') }}</span>
        </div>
      </div>

      <div class="row" v-if="relations.RelatedItems">
        <div class="col m-t-4">
          <SectionTitle>{{ $t('items.RelatedItems') }}</SectionTitle>
        </div>
      </div>

      <div class="row" v-if="relations.RelatedItems">
        <div class="col-md-3 col-sm-6" v-for="(relatedItem, index) in relations.RelatedItems.filter((o,i) => i < (showMoreRelated ? relations.RelatedItems.length : 8))" :key="relatedItem.id">
          <itemcardrelated
            :id="relatedItem.id"
            :key="relatedItem.itemId"
            :summaryitem="relatedItem"
            gtag-list="RelatedItems"
            :gtag-position="index"
            @click="goToItem(relatedItem)"
          />
        </div>
      </div>

      <div class="row" v-if="relations.RelatedItems && relations.RelatedItems.length > 4">
          <div
          class="col"
          @click="showMoreRelated = !showMoreRelated"
        >
          <span
            v-if="showMoreRelated"
            class="link-expand-filters less"
          >{{ $t('items.showLess') }}</span>
          <span
            v-else
            class="link-expand-filters"
          >{{ $t('items.showMore') }}</span>
        </div>
      </div>

      <div class="row" v-if="relations.serviceparts" id="serviceparts">
        <div class="col m-t-4">
          <SectionTitle>{{ $t('items.serviceparts') }}</SectionTitle>
        </div>
      </div>

      <div class="row" v-if="relations.serviceparts">
        <div class="col-md-3 col-sm-6" v-for="(relatedItem, index) in relations.serviceparts.filter((o,i) => i < (showMoreServiceparts ? relations.serviceparts.length : 8))" :key="relatedItem.id">
          <itemcardrelated
                  :id="relatedItem.id"
                  :key="relatedItem.itemId"
                  :summaryitem="relatedItem"
                  gtag-list="RelatedItems"
                  :gtag-position="index"
                  @click="goToItem(relatedItem)"
          />
        </div>
      </div>

      <div class="row" v-if="relations.serviceparts && relations.serviceparts.length > 4">
        <div
                class="col"
                @click="showMoreServiceparts = !showMoreServiceparts"
        >
          <span
                  v-if="showMoreServiceparts"
                  class="link-expand-filters less"
          >{{ $t('items.showLess') }}</span>
          <span
                  v-else
                  class="link-expand-filters"
          >{{ $t('items.showMore') }}</span>
        </div>
      </div>

    </div>

    <div class="container legal"  v-if="!isPending">
      <div class="row">
        <div
          class="col"
        >
        * {{$t('general.legal.spec')}}<br>
        ** {{$t('general.legal.img')}}
        </div>
      </div>
    </div>

    <bcf-modal
      v-if="modal"
      class="modal--addedToCart"
      @close="closeCartModal"
    >
      <bcfAsideHeader :close="closeCartModal">
        {{ $t('cart.inCart') }}
      </bcfAsideHeader>
      <bcfAsideContent>
        <p class="m-t-2">
          {{ $t('cart.successAddedToCart') }}
        </p>

        <div v-if="relations.RelatedItems">
          <SectionTitle>{{$t('cart.successRelatedItems')}}</SectionTitle>

          <carousel
            class="m-b-2"
            :navigationEnabled="true"
            navigationNextLabel="<i class='uil uil-arrow-right'></i>"
            navigationPrevLabel="<i class='uil uil-arrow-left'></i>"
            :paginationEnabled="false"
          >
            <slide v-for="(relatedItem, index) in relations.RelatedItems"
            :key="relatedItem.itemId"
            :data-index="index"
            perPage="3"
            style="max-width: 33.3%;">
              <itemcardrelatedsmall
                :summaryitem="relatedItem"
                gtag-list="RelatedItemsSmall"
                :gtag-position="index"
                @click="goToSmallRelatedItem(relatedItem)"
              />
            </slide>
          </carousel>

          <SectionTitle>{{$t('cart.successNextSteps')}}</SectionTitle>
        </div>

        <list-item icon-before>
          <i class="uil uil-arrow-circle-right" />
          <a
            href="#"
            @click="closeCartModal"
          >{{ $t('cart.continueShopping') }}</a>
        </list-item>
        <list-item icon-before>
          <i class="uil uil-shopping-cart" />
          <router-link :to="{name:'cart'}">
            {{ $t('cart.toCart') }}
          </router-link>
        </list-item>
      </bcfAsideContent>
    </bcf-modal>

    <bcf-aside
      v-if="technologyModal"
      @close="closeTechnologyModal"
    >
      <bcfAsideHeader :close="closeTechnologyModal">
        {{ technologyModalContent.description }}
      </bcfAsideHeader>
      <bcfAsideContent>
        <div class="m-t-2">
          <picture v-if="technologyModalContent.images && technologyModalContent.images.image">
            <!--This is the optional AVIF image-->
            <source type="image/avif" :srcset="technologyModalContent.images.image.highres_avif">
            <!--This is the fallback JPEG image-->
            <img :src="technologyModalContent.images.image.highres" lazy="true" class="product__technology__image__detail" />
          </picture>
          <p v-html="technologyModalContent.detail_description" />
        </div>

        <list-item
          v-if="technologyModalContent.technology_guide"
          icon-before
        >
          <i class="uil uil-book-open" />
          <a
            :href="technologyModalContent.technology_guide"
            target="_blank"
          >{{ $t('items.viewManual') }}</a>
        </list-item>
      </bcfAsideContent>
    </bcf-aside>

    <sharepoint-aside
      v-if="mediaAside"
      @close="closeMediaAside"
      :driveId="item.attributes.sharepoint_drive_id"
      :itemId="item.attributes.sharepoint_folder_path">
    </sharepoint-aside>

    <accountDetailNewsAndActions :id="item.$$price.$$mediafeed.id" v-if="showPromoInfo" :close="closePromoInfo"></accountDetailNewsAndActions>

    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import bcfModal from '@/components/bcfModal.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import ListItem from '@/elements/ListItem.vue';
import availability from '@/elements/Availability.vue';
import loader from '@/elements/loader.vue';
import brandLogo from '@/elements/brandLogo.vue';
import ItemsNav from '@/components/ItemsNav.vue';
import Message from '@/components/Message.vue';
import mediaGallery from '@/components/itemDetails/mediaGallery.vue';
import specifications from '@/components/itemDetails/specifications.vue';
import geo from '@/components/itemDetails/geo.vue';
import variants from '@/components/itemDetails/variants.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import price from '@/elements/Price.vue';
import itemcardrelated from '@/views/items/itemCardRelated.vue';
import itemcardrelatedsmall from '@/views/items/itemCardRelatedSmall.vue';
import loadingOverlay from '@/elements/LoadingOverlay.vue';
import { availabilityText } from '@/utils/cartUtils';
import pageNotFound from '@/views/pageNotFound.vue';
import SharepointAside from '@/views/account/about/sharepoint/sharepointAside.vue';
import accountDetailNewsAndActions from '@/views/account/about/DetailNewsAndActions/view.vue';
import { NotFoundApiError } from '@/api/apiErrors';
import { sortOnPosition } from '@/utils/itemUtils';
import { formatDate } from '@/utils/dateUtils';
import OrderedItem from '@/components/item/orderedItem.vue';

import dialogs from '@/utils/dialogs';
import AssignmentDialog from '@/views/account/account/mycustomers/assignmentDialog.vue';
import { assignedItemFromSelected } from '@/views/account/account/mycustomers/utils';
import {
  GetItemByid,
  GetItemRelations, QueryAssignableItems,
} from '../api/api';

/** Context */

let originalBackId = 0;

export default {
  name: 'Itemdetail',
  metaInfo() {
    return {
      title:
        this.item && this.item.attributes
          ? this.item.attributes.description
          : this.$t('header.products'),
    };
  },
  components: {
    bcfModal,
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    ListItem,
    availability,
    ItemsNav,
    Message,
    loader,
    brandLogo,
    mediaGallery,
    SectionTitle,
    price,
    itemcardrelated,
    itemcardrelatedsmall,
    loadingOverlay,
    specifications,
    geo,
    variants,
    SharepointAside,
    accountDetailNewsAndActions,
    pageNotFound,
    OrderedItem,
  },
  props: {
    itemid: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      item: null,
      res: null,
      modal: false,
      videoGallery: [],
      hasVideoGallery: false,
      currentVideo: 1,
      totalVideo: 0,
      mediaGallery: [],
      hasMediaGallery: false,
      hasGeo: false,
      variantFilters: [],
      relations: {},
      isPending: true,
      variantPending: false,
      technologyModal: false,
      sizeModal: true,
      technologyModalContent: {},
      downloads: [],
      showMoreOutlet: false,
      showMoreRelated: false,
      showMoreServiceparts: false,
      mediaAside: false,
      showPromoInfo: false,
      notFound: false,
      showAssignable: false,
      assignable: {
        loaded: false,
        items: [],
        exactMatch: [],
        other: [],
        counts: {
          all: 0,
          exactMatch: 0,
          other: 0,
        },
      },
    };
  },
  computed: {
    viewableOnWebsite() {
      if (this.item && this.item.attributes && this.item.attributes.groups) {
        if (this.item.attributes.groups.includes('VisibleOnB2C_OLD') || this.item.attributes.groups.includes('VisibleOnB2C_YES')) {
          if (this.item.attributes.item_brand === 'Ridley' || this.item.attributes.item_brand === 'BCF Cycling Wear') {
            return `https://www.ridley-bikes.com/bikes/${this.item.externalid}`;
          } if (this.item.attributes.item_brand === 'Eddy Merckx') {
            return `https://www.eddymerckx.com/bikes/${this.item.externalid}`;
          }
        }
      }
      return false;
    },
    isConfidential() {
      return this.item && this.item.attributes && this.item.attributes.confidential_until && new Date() < new Date(this.item.attributes.confidential_until);
    },
    ...mapState({
      attributeTranslations: (state) => state.language.attributeTranslations,
      showRetailPrices: (state) => state.items.showRetailPrices,
      userEmail: (state) => state.auth.user.email,
      ENABLE_BUILD_YOUR_OWN: (state) => [4].includes(state.auth.user.type) || process.env.VUE_APP_ENABLE_BUILD_YOUR_OWN === 'true',
    }),
    hasMedia() {
      return this.item.attributes.sharepoint_drive_id && this.item.attributes.sharepoint_folder_path;
    },
    availabilityTable() {
      if (this.item && this.item.$$availability) {
        const { $$availability } = this.item;
        return $$availability.sort((a, b) => a.quantity - b.quantity).reduce((availabilityTable, av, i) => {
          try {
            if (availabilityTable.length === 0) {
              availabilityTable.push({
                fromQ: av.quantity,
                text: availabilityText($$availability, av.quantity, this.showRetailPrices),
                type: 'text',
                date: av.date,
              });
            } else if (i === $$availability.length - 1
             && !$$availability[i].date && availabilityTable[availabilityTable.length - 1].date) {
              availabilityTable[availabilityTable.length - 1].toQ = av.quantity - 1;
              availabilityTable.push({
                fromQ: av.quantity - 1,
                type: 'contactUs',
              });
            } else if (i === $$availability.length - 1
             && availabilityTable[availabilityTable.length - 1].date) {
              availabilityTable[availabilityTable.length - 1].toQ = av.quantity;
              availabilityTable.push({
                fromQ: av.quantity,
                type: 'contactUs',
              });
            } else if (availabilityTable[availabilityTable.length - 1].text
              !== availabilityText($$availability, av.quantity, this.showRetailPrices)) {
              availabilityTable[availabilityTable.length - 1].toQ = av.quantity - 1;
              availabilityTable.push({
                fromQ: av.quantity,
                text: availabilityText($$availability, av.quantity, this.showRetailPrices),
                type: 'text',
                date: $$availability[i].date,
              });
            }
          } catch (e) {
            console.error(e, $$availability[i + 1]);
          }
          return availabilityTable;
        }, []).map((o, i, a) => {
          if (o.fromQ === o.toQ) {
            o.quantity = o.fromQ;
          } else if (!o.toQ && o.type === 'contactUs') {
            o.quantity = `${o.fromQ}+`;
          } else if (!o.toQ && a.length > 1) {
            o.quantity = `${o.fromQ - 1}+`;
          } else if (!o.toQ) {
            o.quantity = `${o.fromQ}`;
          } else {
            o.quantity = `${o.fromQ} - ${o.toQ}`;
          }
          return o;
        });
      }
      return [];
    },
  },
  watch: {
    $route() {
      this.loadItemInformation();
    },
  },
  created() {
    originalBackId = this.$route.params.itemid;
    this.loadItemInformation();
  },
  methods: {
    goToBuildYourOwn() {
      this.$router.push(`/items/configurator/${this.item.attributes.modelcode}`);
    },
    formatDate,
    gotoVideo(videoKey) {
      this.currentVideo = Object.keys(this.videoGallery).indexOf(videoKey) + 1;
    },
    availabilityText,
    scrollTo(div, smooth = true) {
      let offsetPosition = 0;
      let offsetPositionInner = 0;
      const x = document.getElementsByClassName('main__inner')[0];
      const element = document.getElementById(div);
      const headerOffset = 90;
      const headerOffsetInner = 55;
      const currentScroll = document.documentElement.scrollTop;
      const currentScrollInner = x.scrollTop;
      const elementPosition = element.getBoundingClientRect().top;
      offsetPosition = elementPosition - headerOffset + currentScroll;
      offsetPositionInner = elementPosition - headerOffsetInner + currentScrollInner;

      x.scroll({
        top: offsetPositionInner,
        behavior: smooth ? 'smooth' : 'auto',
      });
      window.scroll({
        top: offsetPosition < 0 ? 0 : offsetPosition,
        behavior: smooth ? 'smooth' : 'auto',
      });
    },
    scrollToTop() {
      const x = document.getElementsByClassName('main__inner')[0];
      x.scroll({
        top: 0,
        behavior: 'auto',
      });
      window.scroll({
        top: 0,
        behavior: 'auto',
      });
    },
    openTechnologyModal(technology) {
      this.technologyModal = true;
      this.technologyModalContent = technology;
    },
    openGallery() {
      if (this.$refs.mediaGallery) {
        this.$refs.mediaGallery.openGallery(0);
      }
    },
    goToItem(item) {
      this.$router.push(`/items/${item.itemId}`);
      this.scrollToTop();
    },
    goToSmallRelatedItem(item) {
      this.closeCartModal();
      this.goToItem(item);
    },
    goBack() {
      if (!this.$store.state.global.previousRoute) {
        const thePath = this.$route.path.split('/');
        thePath.pop();
        this.$router.push(`${thePath.join('/')}#${originalBackId}`);
      } else {
        this.$router.go('-1');
      }
    },
    addToCart() {
      this.modal = true;
      this.$store.dispatch('cart/addProductToCart', { product: this.item });
    },
    closeTechnologyModal() {
      this.technologyModal = false;
    },
    closeCartModal() {
      this.modal = false;
    },
    closeMediaAside() {
      this.mediaAside = false;
    },
    closePromoInfo() {
      this.showPromoInfo = false;
    },
    async loadItemInformation() {
      this.showPromoInfo = false;
      try {
        // Get information
        this.variantPending = true;
        const itemId = this.$route.params.itemid;
        const customerId = this.$store.state.customer.selectedCustomerId;
        const lang = this.$store.state.language.currentApi;
        const getItem = GetItemByid(
          itemId,
          lang,
          customerId,
        );
        const requests = await Promise.all([
          getItem,
          GetItemRelations(
            itemId,
            lang,
            customerId,
          ),
        ]);
        const [item, relations] = requests;
        this.relations = relations;

        // handle empty Technology
        if (
          item.attributes.technology
          && !Array.isArray(item.attributes.technology)
        ) {
          item.attributes.technology = [item.attributes.technology];
        }
        if (item.attributes.technology) {
          item.attributes.technology = item.attributes.technology.filter(
            (o) => o,
          );
        }

        this.item = item;

        // Downloads
        this.downloads = Object.keys(this.item.attributes)
          .filter((o) => [
            'frame_passport',
            'technology_guide',
            'assembly_manual',
            'safetynotice',
            'ownermanual',
            'wheel_passport',
            'frame_options_printsheeta4',
            'frame_options_printsheeta5',
          ].includes(o))
          .map((o) => ({
            label: this.attributeTranslations[o]
              ? this.attributeTranslations[o]
              : o,
            url: this.item.attributes[o],
          }));

        if (!this.item.attributes.images) {
          this.item.attributes.images = {};
        }
        // Video gallery
        this.videoGallery = Object.keys(this.item.attributes)
          .filter((key) => key.match(/^video[1-5]/))
          .reduce((obj, key) => {
            // eslint-disable-next-line no-console
            console.log(obj, key);
            obj[key] = this.item.attributes[key];
            return obj;
          }, {});
        // console.log(this.videoGallery[`video${1}`]);
        this.totalVideos = Object.keys(this.videoGallery).length;
        this.hasVideoGallery = this.totalVideos > 0;
        // Media Gallery
        this.mediaGallery = Object.keys(this.item.attributes.images)
          .filter((key) => key.match(/^image[0-9]/))
          .reduce((obj, key) => {
            obj[key] = this.item.attributes.images[key];
            return obj;
          }, {});
        this.hasMediaGallery = Object.keys(this.mediaGallery).length > 0;
        // Geo
        this.hasGeo = (!!this.item.attributes.images.geo_image1) || (this.item.attributes.excel_frame_geometry);

        this.hasVariants = !!(this.relations.otherdesigns
                || this.relations.othersizes
                || this.relations.othergroupsets
                || this.relations.othergroupsetsdifferentdesign
                || this.relations.otherdesignsdifferentgroupset);

        if (this.relations.outlet) {
          this.relations.outlet = this.relations.outlet.sort(sortOnPosition);
        }

        if (this.relations.RelatedItems) {
          this.relations.RelatedItems = this.relations.RelatedItems.sort(sortOnPosition);
        }

        this.isPending = false;
        this.variantPending = false;

        const catPath = {
          outlet: '/items/outlet/',
          marketing: '/items/promo/',
          components: '/items/parts/',
          accessories: '/items/accessories/',
          bikes: '/items/bikes/',
        };
        if (catPath[this.item.attributes.category]) {
          const replaceWith = catPath[this.item.attributes.category] + this.$route.params.itemid;
          if (replaceWith !== this.$route.path) {
            this.$router.replace(catPath[this.item.attributes.category] + this.$route.params.itemid);
          }
        }

        if (item.attributes.model) {
          this.showAssignable = true;
          const assignable = this.fetchAssignable();
          this.setAssignable(await assignable);
        }

        // Update page title
        this.$meta().resume({ refresh: true });

        // Send view to GA
        this.$gtag.event('view_item', {
          items: [
            {
              id: this.item.externalid,
              name: this.item.description,
              brand: this.item.attributes.item_brand,
              category: this.item.attributes.category,
              price: this.item.$$price.price,
            },
          ],
        });
      } catch (err) {
        if (err instanceof NotFoundApiError) {
          this.notFound = true;
        }
      }
    },
    async fetchAssignable() {
      const { itemid } = this.$route.params;
      const customerId = this.$store.state.customer.selectedCustomerId;
      this.assignable = { ...this.assignable, loaded: false };
      return await QueryAssignableItems(
        customerId,
        {
          limit: undefined,
          filters: { itemid },
        },
      );
    },
    setAssignable(assignable) {
      // Organize assignable items
      const items = assignable.filter((a) => a.item.attributes.frame_size.default === this.item.attributes.frame_size).map((a) => {
        const matches = [
          a.item.externalid === this.item.externalid,
          ...['frame_size', 'paint_des', 'groupset'].map((k) => a.item.attributes[k]?.default === this.item.attributes[k]),
        ];
        const matchVal = matches.indexOf(true);
        const order = matches.map((v, i) => (v ? 2 ** (matches.length - i) : 0)).reduce((a, b) => a + b, 0);
        return {
          order,
          match: matchVal, // 0 on exact match, 1 on frame_size, 2 for paint_des, ...)
          ...a,
        };
      }).sort((a, b) => a.order - b.order);

      const exactMatch = items.filter((x) => x.match === 0);// ;
      const other = items.filter((x) => x.match >= 1);

      this.assignable = {
        loaded: true,
        items,
        exactMatch,
        other,
        counts: {
          all: items.map((x) => x.items.length).reduce((a, b) => a + b, 0),
          exactMatch: exactMatch.map((x) => x.items.length).reduce((a, b) => a + b, 0),
          other: other.map((x) => x.items.length).reduce((a, b) => a + b, 0),
        },
      };
    },
    async assignOrderedItem(item, origEvent) {
      const result = await dialogs.show({
        component: AssignmentDialog,
        props: {
          relation: null, // need to toggle somewhere to query or make a relation on the spot
          value: {
            items: item ? [assignedItemFromSelected(item, origEvent)] : [],
          },
          autofocusSearch: true, // will focus customer
        },
      });
      if (result) {
        await this.fetchAssignable().then(this.setAssignable);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.legal {
  border-top: 1px solid #ececec;
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 0.8em;
  color: #afafaf;
}

.viewableOnWebsite{
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  a {
    text-decoration: none;
    color: #afafaf;
  }
  i {
    font-size: 10px;

  }
}
</style>
